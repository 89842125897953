@import "../base/var";
@import "../base/fonts";
@import "../base/buttons";

.verification-code {
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.register-wrapper {
  width: 70em;
  margin: 3em 0 5em 0;
  background-color: $very-light-grey;
  min-height: 60vh;
  box-shadow: -1px 3px 29px 2px rgba(0, 0, 0, 0.2);
  z-index: 1;
  @media screen and (max-width: $breakSmallTab) {
    margin: 0;
    min-height: 70vh;
    width: 100%;
    box-shadow: none;
  }
  .register-container {
    margin: 2em 4em 4em 4em;
    @media screen and (max-width: $breakSmallTab) {
      margin: 2em 2em 4em 2em;
    }
    h1,
    h2 {
      text-transform: uppercase;
      color: $light-red-title;
      font-size: 1em;
      margin-bottom: 1em;
      letter-spacing: 4px;
      font-family: bold;
    }
    .register-form {
      display: block;
      margin-bottom: 1em;
      .radio-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 1em 0 1em 0;
        @media screen and (max-width: $breakSmallTab) {
          width: 100%;
          flex-wrap: wrap;
        }
      }
      table {
        width: 100%;
        margin-bottom: 2em;
        tr > td {
          width: 50%;
          @media screen and (max-width: $breakSmallTab) {
            display: inline-block;
            width: 100%;
          }
        }
        .specific {
          padding-right: 1em;
          @media screen and (max-width: $breakSmallTab) {
            padding-right: 0;
          }
          .form-control {
            background-color: red;
          }
        }
      }
    }
    .margin-title {
      margin-bottom: 2em;
    }
    .margin-switch {
      margin-bottom: 1em;
      @media screen and (max-width: $breakMobile) {
        margin-bottom: 2em;
      }
    }
    .switch-container {
      position: relative;
      .slider-text {
        position: absolute;
        top: 7px;
        left: 70px;
        font-size: 1em;
        font-family: medium;
        color: $blue-radio-label;
        @media screen and (max-width: $breakSmallTab) {
          top: 0px;
        }
        .italic{
          font-style: italic;
        }
      }
    }
  }
  .buttons-container {
    margin: 2em 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .buttons-wrapper {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: $breakSmallTab) {
        width: 100%;
        display: block;
      }
      .cancel-register {
        margin-right: 1em;
        @media screen and (max-width: $breakSmallTab) {
          margin: 1em 0;
        }
      }
    }
  }
  .margin-mentions-legales{
    margin-top: 2em;
  }
  .mentions-legales {
    color: $dark-blue;
    font-size: 1em;
    font-family: medium;
    @media screen and (max-width: $breakSmallTab) {
      margin-top: 50px;
    }
    a{
      color: $dark-blue;
    }
  }
  .mentions-legales-newsletter {
    color: $dark-blue;
    font-size: 1em;
    font-family: medium;
    @media screen and (max-width: $breakSmallTab) {
      margin-top: 75px;
    }
    a{
      color: $dark-blue;
    }
  }
}
