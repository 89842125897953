@import "../base/var";
@import "../base/fonts";

.phone-input {
  width: 100%;
  position: relative;
}

.input-floating {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin-top: 1em;
  margin-bottom: 5px;
  width: 100%;
  @media screen and (max-width: $breakSmallTab) {
    width: 100%;
    margin-top: 1em;
  }
  .country-code-selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 15%;
    background-color: $light-grey;
    font-size: 1.75em;
    font-family: medium;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    cursor: pointer;
    @media screen and (max-width: $breakSmallTab) {
      width: 20%;
    }
  }
  .input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .input-container:focus-within label {
    transform: translate(0, 12px) scale(0.8);
    color: $blue-radio-label;
  }

  .input-container .filled {
    transform: translate(0, 12px) scale(0.8);
    color: $blue-radio-label;
  }

  .input-container label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: $input-label;
    font-size: 1em;
    font-family: medium;
    line-height: 1;
    left: 16px;
  }
  .input-container input {
    height: 60px;
    border-radius: 8px;
    border: none;
    padding: 24px 16px 4px 16px;
    font-size: 1em;
    font-family: semi-bold;
    color: $blue-marina;
    line-height: 1;
    background-color: $very-light-grey;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  .input-container input:disabled {
    height: 60px;
    border-radius: 8px;
    border: none;
    padding: 24px 16px 4px 16px;
    font-size: 1em;
    font-family: semi-bold;
    color: $blue-marina;
    line-height: 1;
    background-color: $very-light-grey;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  .required-only {
    width: 50px;
    height: 50px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    padding-top: 5px;
    padding-right: 10px;
  }
  .required-right-icon {
    width: 50px;
    height: 50px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: none;
  }
  span {
    text-transform: capitalize;
    font-size: 0.75em;
    color: $grey;
    font-family: medium;
  }
  i,
  .right-icon {
    width: 50px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    @media screen and (max-width: $breakSmallTab) {
      margin-right: 10px;
    }
    .icon {
      path {
        fill: $dark-blue;
      }
    }
    .icon:hover {
      path {
        fill: $blue-marina;
      }
    }
  }
}
.input-border {
  border: 1px solid $grey-input-border;
}
.input-border-error {
  border: 1px solid $alert;
}

.input-border-error-newsletter-footer {
  border: 1.5px solid $alert;
}

.code-input {
  input {
    font-family: medium;
    margin: 4px;
    width: 70px;
    border-radius: 10px;
    text-align: center;
    font-size: 2.5em;
    height: 70px;
    padding-left: 7px;
    background-color: white;
    color: $blue-marina;
    border: 1px solid $grey-input-border;
    @media screen and (max-width: $breakSmallTab) {
      height: 60px;
      width: 60px;
      font-size: 2em;
    }
  }
}
.code-input-error {
  input {
    font-family: medium;
    margin: 4px;
    width: 70px;
    border-radius: 10px;
    text-align: center;
    font-size: 2.5em;
    height: 70px;
    padding-left: 7px;
    background-color: $alert-background;
    color: $alert;
    border: 1px solid $alert;
    @media screen and (max-width: $breakSmallTab) {
      height: 60px;
      width: 60px;
      font-size: 2em;
    }
  }
}

.code-input-success {
  input {
    font-family: medium;
    margin: 4px;
    width: 70px;
    border-radius: 10px;
    text-align: center;
    font-size: 2.5em;
    height: 70px;
    padding-left: 7px;
    background-color: $success-background;
    color: $green;
    border: 1px solid $green;
    @media screen and (max-width: $breakSmallTab) {
      height: 60px;
      width: 60px;
      font-size: 2em;
    }
  }
}

.country-code-list {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgb(0 0 0 / 6%);
  box-sizing: border-box;
  margin-top: -10px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 1000;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  .country-code-option {
    font-size: 1em;
    font-family: medium;
    color: $blue-marina;
    padding: 10px;
    cursor: pointer;
  }
  .country-code-option:hover {
    background-color: $light-grey;
  }
  .selected {
    background-color: $blue-transparent;
  }
}
