@import "../base/_var.scss";

header {
  height: 100px;
  position: relative;
  background-color: $light-grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $breakSmallTab) {
    height: 80px;
    padding: 0;
  }
  button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 30%;
    left: 5%;
    width: 40px;
    height: 40px;
    border-radius: 2.5em;
    background-color: $white;
    border: none;
    cursor: pointer;
    outline: none;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.35);
    @media screen and (max-width: $breakSmallTab) {
      top: 30%;
      left: 5%;
      width: 30px;
      height: 30px;
      border-radius: 2em;
    }
  }
  button:hover {
    background-color: $light-grey-footer;
  }
  .logo-my-inexplore {
    position: absolute;
    top: 35%;
    left: 11%;
    width: 146px;
    height: 37px;
    @media screen and (max-width: $breakTab) {
      display: none;
    }
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    a {
      .logo-inrees,
      .logo-inexplore {
        width: 87px;
        @media screen and (max-width: $breakSmallTab) {
          width: 70px;
        }
      }
      .logo-inexploreTV {
        width: 102px;
        @media screen and (max-width: $breakSmallTab) {
          width: 80px;
        }
      }
    }
  }
}

.container-full-width {
  width: 65%;
}
.last-step {
  width: 40%;
  @media screen and (max-width: $breakSmallTab) {
    width: 65%;
  }
}
