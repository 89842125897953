@import "./var";

/* buttons styles */

.btn-gradient {
  min-width: 25%;
  height: 3.125em;
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: $dark-blue;
  border: none;
  border-radius: 25px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.35);
  font-family: semi-bold;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  @media screen and (max-width: $breakSmallTab) {
    width: 100%;
  }
  span {
    display: block;
    font-family: semi-bold;
    font-size: 1em;
    background: linear-gradient(
      90deg,
      $light-red 0%,
      $orange 50%,
      $yellow 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.btn-gradient:hover {
  background: linear-gradient(90deg, $light-red 0%, $orange 50%, $yellow 100%);
  @media screen and (max-width: $breakSmallTab) {
    background: $dark-blue;
  }
  span {
    display: block;
    background: linear-gradient($white, $white);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: $breakSmallTab) {
      -webkit-text-fill-color: $white;
    }
  }
}

.btn-mon-compte {
  width: 75%;
}

.btn-footer-newsletter {
  width: 20%;
  height: 45px;
  display: block;
  border: none;
  background-color: $dark-blue-footer;
  margin-right: 3px;
  border-radius: 30px;
}
.btn-footer-newsletter:hover {
  background-color: $blue;
}
.link-button {
  width: 100%;
  text-decoration: none;
}
.btn-clear {
  background-color: transparent;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  text-decoration: underline;
  color: $blue-marina;
}
.btn-clear-dark-blue {
  font-family: medium;
  font-size: 1em;
  color: $dark-blue;
  cursor: pointer;
}
.btn-clear-dark-blue:hover {
  color: $blue-marina;
}
.btn-clear-small-grey {
  font-family: medium;
  font-size: 0.875em;
  color: $grey;
  cursor: pointer;
}
.btn-clear-small-grey:hover {
  color: $blue-marina;
}
.btn-outline-dark-blue {
  min-width: 25%;
  height: 3.125em;
  display: block;
  border: 2px solid $dark-blue;
  border-radius: 25px;
  color: $dark-blue;
  position: relative;
  font-family: semi-bold;
  font-size: 1em;
  background-color: $white;
  cursor: pointer;
  @media screen and (max-width: $breakSmallTab) {
    width: 100%;
  }
}
.btn-outline-dark-blue:hover {
  color: $white;
  background-color: $dark-blue;
}
.btn-red {
  min-width: 25%;
  height: 3.125em;
  display: block;
  border: none;
  border-radius: 25px;
  color: $alert;
  position: relative;
  font-family: semi-bold;
  font-size: 1em;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.35);
  background-color: $transparency-red;
  cursor: pointer;
  @media screen and (max-width: $breakSmallTab) {
    width: 100%;
  }
}
.btn-red:hover {
  color: $white;
  background-color: $alert;
}
